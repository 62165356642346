<template>
  <div>
    <div class="tile is-ancestor my-6 pt-6">
      <div class="tile is-vertical is-8">
        <article class="mx-6">
          <figure class="crop">
            <img
              class="my-6 mx-6"
              :src="require('@/assets/Services/IMG_9366-Editar.jpg')"
              width="90%"
            />
          </figure>
        </article>
      </div>
      <div class="tile my-5 mx-3 mr-6 pr-6 is-vertical">
        <article>
          <h1
            class="title mt-4 has-text-primary-dark has-text-left has-text-centered-mobile has-text-break"
          >
            {{ $t("home1") }}
          </h1>
          <p class="my-4 has-text-left has-text-centered-mobile">
            {{ $t("servicestext") }}
          </p>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor mx-6 mt-6">
      <div class="tile mx-6 is-vertical">
        <article>
          <h1
            class="title mt-6 has-text-primary-dark has-text-right has-text-break"
          >
            {{ $t("mechanicalhead") }}
          </h1>
          <p class="my-4 has-text-right has-text-break">
            {{ $t("mechanicaltext") }}
          </p>
        </article>
      </div>
      <div class="tile is-vertical is-6">
        <article class="mx-6">
          <figure>
            <img
              class="my-6 mx-6"
              :src="require('@/assets/Services/IMG_8509.jpg')"
              width="600"
            />
          </figure>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor mx-6 mt-6">
      <div class="tile is-vertical is-6">
        <article class="mx-6">
          <figure>
            <img
              class="my-6 mx-6"
              :src="require('@/assets/Services/IMG_8771.jpg')"
              width="600"
            />
          </figure>
        </article>
      </div>
      <div class="tile mx-6 is-vertical">
        <article>
          <h1
            class="title mt-6 has-text-primary-dark has-text-left has-text-break"
          >
            {{ $t("customheader") }}
          </h1>
          <p class="my-4 has-text-left">
            {{ $t("customtext") }}
          </p>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor mx-6 mt-6">
      <div class="tile mx-6 is-vertical">
        <article>
          <h1
            class="title mt-6 has-text-primary-dark has-text-right has-text-break"
          >
            {{ $t("integrationheader") }}
          </h1>
          <p class="my-4 has-text-right has-text-break">
            {{ $t("integrationtext") }}
          </p>
        </article>
      </div>
      <div class="tile is-vertical is-6">
        <article class="mx-6">
          <figure>
            <img
              class="my-6 mx-6"
              :src="require('@/assets/Services/IMG_8906.jpg')"
              width="600"
            />
          </figure>
        </article>
      </div>
    </div>    
    <div>      
      <h1 class="title has-text-primary-dark has-text-left mx-6 pl-6">
          {{ $t("work") }}
        </h1>
        <!-- Start Carousel -->        
        <div >
          <div class="carousel-item my-6">
            <h1 class="subtitle has-text-primary-dark has-text-left mx-6 pl-6">
              {{ $t("work1") }}
            </h1>
            <figure>
              <img :src="require('@/assets/Services/Imagen 2.png')" alt="" />
            </figure>
            <p class="mx-6">{{ $t("work1text") }}</p>
          </div>
          <div class="carousel-item my-6">
            <h1 class="subtitle has-text-primary-dark has-text-left mx-6 pl-6">
              {{ $t("work2") }}
            </h1>
            <figure>
              <img :src="require('@/assets/Services/Imagen 3.png')" alt="" />
            </figure>
            <p class="mx-6">{{ $t("work2text") }}</p>
          </div>
        </div>
        <!-- End Carousel -->      
    </div>
  </div>
</template>

<script>
import bulmaCarousel from "bulma-extensions/bulma-carousel/dist/js/bulma-carousel.min.js";
export default {
  mounted: function() {
    new bulmaCarousel(document.getElementById("bulmaCarousel"));
  }
};
</script>

<style scoped>
p {
  white-space: pre;
}
</style>
